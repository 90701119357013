body {
    background: #F5F4F2;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: "CoFo Sans";
    src: url("../public/fonts/CoFoSans-Regular.woff") format('truetype');

}

@font-face {
    font-family: "Coil";
    src: url("../public/fonts/Coil-Regular.woff") format('truetype');

}

p,
ul,
ol {
    @apply mb-4;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
